import { NgModule } from '@angular/core';
import { OnesignalNgxComponent } from './onesignal-ngx.component';



@NgModule({
  declarations: [OnesignalNgxComponent],
  imports: [
  ],
  exports: [OnesignalNgxComponent]
})
export class OnesignalNgxModule { }
