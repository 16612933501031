import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'onesignal-onesignal-ngx',
  template: `
    <p>
      onesignal-ngx works!
    </p>
  `,
  styles: [
  ]
})
export class OnesignalNgxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
