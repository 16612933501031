<mat-tab-header #tabHeader
                [selectedIndex]="selectedIndex || 0"
                [disableRipple]="disableRipple"
                [disablePagination]="disablePagination"
                (indexFocused)="_focusChanged($event)"
                (selectFocusedIndex)="selectedIndex = $event">

  <div class="mdc-tab mat-mdc-tab mat-mdc-focus-indicator"
       #tabNode
       role="tab"
       matTabLabelWrapper
       cdkMonitorElementFocus
       *ngFor="let tab of _tabs; let i = index"
       [id]="_getTabLabelId(i)"
       [attr.tabIndex]="_getTabIndex(i)"
       [attr.aria-posinset]="i + 1"
       [attr.aria-setsize]="_tabs.length"
       [attr.aria-controls]="_getTabContentId(i)"
       [attr.aria-selected]="selectedIndex === i"
       [attr.aria-label]="tab.ariaLabel || null"
       [attr.aria-labelledby]="(!tab.ariaLabel && tab.ariaLabelledby) ? tab.ariaLabelledby : null"
       [class.mdc-tab--active]="selectedIndex === i"
       [ngClass]="tab.labelClass"
       [disabled]="tab.disabled"
       [fitInkBarToContent]="fitInkBarToContent"
       (click)="_handleClick(tab, tabHeader, i)"
       (cdkFocusChange)="_tabFocusChanged($event, i)">
    <span class="mdc-tab__ripple"></span>

    <!-- Needs to be a separate element, because we can't put
         `overflow: hidden` on tab due to the ink bar. -->
    <div
      class="mat-mdc-tab-ripple"
      mat-ripple
      [matRippleTrigger]="tabNode"
      [matRippleDisabled]="tab.disabled || disableRipple"></div>

    <span class="mdc-tab__content">
      <span class="mdc-tab__text-label">
        <!-- If there is a label template, use it. -->
        <ng-template [ngIf]="tab.templateLabel" [ngIfElse]="tabTextLabel">
          <ng-template [cdkPortalOutlet]="tab.templateLabel"></ng-template>
        </ng-template>

        <!-- If there is not a label template, fall back to the text label. -->
        <ng-template #tabTextLabel>{{tab.textLabel}}</ng-template>
      </span>
    </span>
  </div>
</mat-tab-header>

<div
  class="mat-mdc-tab-body-wrapper"
  [class._mat-animation-noopable]="_animationMode === 'NoopAnimations'"
  #tabBodyWrapper>
  <mat-tab-body role="tabpanel"
               *ngFor="let tab of _tabs; let i = index"
               [id]="_getTabContentId(i)"
               [attr.tabindex]="(contentTabIndex != null && selectedIndex === i) ? contentTabIndex : null"
               [attr.aria-labelledby]="_getTabLabelId(i)"
               [attr.aria-hidden]="selectedIndex !== i"
               [class.mat-mdc-tab-body-active]="selectedIndex === i"
               [ngClass]="tab.bodyClass"
               [content]="tab.content!"
               [position]="tab.position!"
               [origin]="tab.origin"
               [animationDuration]="animationDuration"
               [preserveContent]="preserveContent"
               (_onCentered)="_removeTabBodyWrapperHeight()"
               (_onCentering)="_setTabBodyWrapperHeight($event)">
  </mat-tab-body>
</div>
