<ng-container *ngIf="!_isEditing">
  <span class="mat-mdc-chip-focus-overlay"></span>
</ng-container>

<span class="mdc-evolution-chip__cell mdc-evolution-chip__cell--primary" role="gridcell"
    matChipAction
    [tabIndex]="tabIndex"
    [disabled]="disabled"
    [attr.aria-label]="ariaLabel"
    [attr.aria-describedby]="_ariaDescriptionId">
  <span class="mdc-evolution-chip__graphic mat-mdc-chip-graphic" *ngIf="leadingIcon">
    <ng-content select="mat-chip-avatar, [matChipAvatar]"></ng-content>
  </span>
  <span class="mdc-evolution-chip__text-label mat-mdc-chip-action-label" [ngSwitch]="_isEditing">
    <ng-container *ngSwitchCase="false"><ng-content></ng-content></ng-container>

    <ng-container *ngSwitchCase="true">
      <ng-content *ngIf="contentEditInput; else defaultMatChipEditInput"
                  select="[matChipEditInput]"></ng-content>
      <ng-template #defaultMatChipEditInput><span matChipEditInput></span></ng-template>
    </ng-container>

    <span class="mat-mdc-chip-primary-focus-indicator mat-mdc-focus-indicator" aria-hidden="true"></span>
  </span>
</span>

<span
  class="mdc-evolution-chip__cell mdc-evolution-chip__cell--trailing"
  role="gridcell"
  *ngIf="_hasTrailingIcon()">
  <ng-content select="mat-chip-trailing-icon,[matChipRemove],[matChipTrailingIcon]"></ng-content>
</span>

<span class="cdk-visually-hidden" [id]="_ariaDescriptionId">{{ariaDescription}}</span>
