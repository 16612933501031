<ng-content></ng-content>
<div class="mat-menu-ripple" matRipple
     [matRippleDisabled]="disableRipple || disabled"
     [matRippleTrigger]="_getHostElement()">
</div>

<svg
  *ngIf="_triggersSubmenu"
  class="mat-menu-submenu-icon"
  viewBox="0 0 5 10"
  focusable="false"
  aria-hidden="true"><polygon points="0,0 5,5 0,10"/></svg>
