<mat-tab-header #tabHeader
               [selectedIndex]="selectedIndex || 0"
               [disableRipple]="disableRipple"
               [disablePagination]="disablePagination"
               (indexFocused)="_focusChanged($event)"
               (selectFocusedIndex)="selectedIndex = $event">
  <div class="mat-tab-label mat-focus-indicator" role="tab" matTabLabelWrapper mat-ripple
       cdkMonitorElementFocus
       *ngFor="let tab of _tabs; let i = index"
       [id]="_getTabLabelId(i)"
       [attr.tabIndex]="_getTabIndex(i)"
       [attr.aria-posinset]="i + 1"
       [attr.aria-setsize]="_tabs.length"
       [attr.aria-controls]="_getTabContentId(i)"
       [attr.aria-selected]="selectedIndex === i"
       [attr.aria-label]="tab.ariaLabel || null"
       [attr.aria-labelledby]="(!tab.ariaLabel && tab.ariaLabelledby) ? tab.ariaLabelledby : null"
       [class.mat-tab-label-active]="selectedIndex === i"
       [ngClass]="tab.labelClass"
       [disabled]="tab.disabled"
       [matRippleDisabled]="tab.disabled || disableRipple"
       (click)="_handleClick(tab, tabHeader, i)"
       (cdkFocusChange)="_tabFocusChanged($event, i)">


    <div class="mat-tab-label-content">
      <!-- If there is a label template, use it. -->
      <ng-template [ngIf]="tab.templateLabel" [ngIfElse]="tabTextLabel">
        <ng-template [cdkPortalOutlet]="tab.templateLabel"></ng-template>
      </ng-template>

      <!-- If there is not a label template, fall back to the text label. -->
      <ng-template #tabTextLabel>{{tab.textLabel}}</ng-template>
    </div>
  </div>
</mat-tab-header>

<div
  class="mat-tab-body-wrapper"
  [class._mat-animation-noopable]="_animationMode === 'NoopAnimations'"
  #tabBodyWrapper>
  <mat-tab-body role="tabpanel"
               *ngFor="let tab of _tabs; let i = index"
               [id]="_getTabContentId(i)"
               [attr.tabindex]="(contentTabIndex != null && selectedIndex === i) ? contentTabIndex : null"
               [attr.aria-labelledby]="_getTabLabelId(i)"
               [class.mat-tab-body-active]="selectedIndex === i"
               [ngClass]="tab.bodyClass"
               [content]="tab.content!"
               [position]="tab.position!"
               [origin]="tab.origin"
               [animationDuration]="animationDuration"
               [preserveContent]="preserveContent"
               (_onCentered)="_removeTabBodyWrapperHeight()"
               (_onCentering)="_setTabBodyWrapperHeight($event)">
  </mat-tab-body>
</div>
